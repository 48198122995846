<template>
  <v-card>
    <v-card-text>
      <h1>Error 404</h1>
      <p>{{ $vuetify.lang.t('$vuetify.page_does_not_exist') }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Error404',
  metaInfo: {
    title: 'Error 404'
  }
}
</script>

<style scoped>

</style>
