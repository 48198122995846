<template>
  <div>
    <v-card
      class="rounded-xl pa-4"
      :loading="isLoading"
      :disabled="isLoading"
    >
      <v-card-text>
        <v-row justify="center">
          <v-col
            cols="12"
            lg="6"
            sm="6"
          >
            <v-img
              :src="logo"
              :alt="websiteConfig.appName"
              class="mb-5"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <p>{{ $vuetify.lang.t('$vuetify.proceed') }}</p>
            <h1 class="text-lg-h1 text-md-h2">
              {{ amount }}€
            </h1>
            <p class="text-h5 mt-5">
              {{ $vuetify.lang.t('$vuetify.pay_for_reference') }} <b>{{ paymentRequest.order_reference }}</b>
            </p>
            <p class="teal--text">
              {{ $vuetify.lang.t('$vuetify.doubts') }}
              <v-icon
                x-small
                color="teal"
              >
                mdi-phone
              </v-icon>
              {{ websiteConfig.appPhone }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="my-5" />
        <v-row>
          <v-col
            cols="12"
            md="6"
            :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : ''"
          >
            <p>{{ $vuetify.lang.t('$vuetify.pay_for') }}</p>
            <p class="text-h5 text-uppercase">
              {{ paymentRequest.client_name }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-center"
          >
            <p class="text-uppercase">
              {{ $vuetify.lang.t('$vuetify.payment_limit') }}
            </p>
            <due-date-text
              :due-date="paymentRequest.valid_until"
              :payed-at="paymentRequest.payed_at"
            />
          </v-col>
        </v-row>
        <v-row v-if="paymentRequest.payed_at !== null">
          <v-col class="text-center green--text">
            <v-icon
              color="green"
              x-large
            >
              mdi-check-circle
            </v-icon>
            {{ $vuetify.lang.t('$vuetify.payed_successfully') }}:
            {{ new Date(this.paymentRequest.payed_at) | moment('H:mm:ss DD-MM-YYYY') }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="paymentRequest.payed_at === null && isDueDate(paymentRequest.valid_until) === false">
        <v-btn
          color="primary"
          block
          x-large
          :href="paymentRequest.payment_url"
        >
          {{ $vuetify.lang.t('$vuetify.pay') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import DueDateText from '../components/DueDateText'
import { dueDateMixin } from '../mixins/dueDateMixin'
import { websiteConfig } from '../mixins/websiteConfig'

export default {
  name: 'PaymentPage',
  metaInfo () {
    return {
      title: this.$vuetify.lang.t('$vuetify.process_payment')
    }
  },
  mixins: [dueDateMixin, websiteConfig],
  provide () {
    return {
      $validator: this.$validator
    }
  },
  components: {
    DueDateText
  },
  computed: {
    logo: function () {
      return require(`../assets/logos/${this.websiteId}.png`)
    },
    websiteId: function () {
      return this.$route.params.website
    },
    token: function () {
      return this.$route.params.token
    },
    websiteConfig: function () {
      return this[this.websiteId]
    },
    amount: function () {
      return (this.paymentRequest !== null) ? parseFloat(this.paymentRequest.amount).toLocaleString('es', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }) : 0
    }
  },
  data: () => ({
    isLoading: true,
    paymentRequest: null
  }),
  methods: {
    fetch: function () {
      this.isLoading = true
      fetch(`${process.env.VUE_APP_BACKEND_URL}/payment-request/${this.websiteId}/${this.token}`)
        .then(stream => stream.json())
        .then(data => {
          this.paymentRequest = data
          this.$validator.localize(this.paymentRequest.locale)
          this.$vuetify.lang.current = this.paymentRequest.locale
        })
        .catch(() => {
          this.$router.push({ name: 'error-404-page' })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    if (!this.websiteConfig) {
      this.$router.push({ name: 'error-404-page' })
    }

    this.fetch()
  }
}
</script>

<style scoped>

</style>
