<template>
  <v-card>
    <v-card-text class="red--text text-center">
      <h1 class="text-h1 font-weight-bold">
        Error
      </h1>
      <v-icon
        color="red"
        class="text-h1"
        x-large
      >
        mdi-close-circle
      </v-icon>
      <p>{{ $vuetify.lang.t('$vuetify.payment_error') }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PaymentError',
  metaInfo: {
    title: 'Error'
  }
}
</script>

<style scoped>

</style>
