<template>
  <v-card>
    <v-card-text>
      <div v-if="$vuetify.lang.current === 'es'">
        <h1 class="text-h2 mb-4">
          Condiciones de venta
        </h1>
        <p>
          La realización de un pedido, supone la aceptación de las Condiciones Generales de Venta.<br>
        </p>
        <p>
          Los productos pueden sufrir modificaciones con respecto a la reproducción gráfica en catálogo o web.<br>
          Los precios pueden variar sin previo aviso.<br>
          Los precios se expresan en euros y no incluyen el impuesto sobre el valor añadido (I.V.A.) u otros que
          pudieran corresponder en cada momento, salvo error tipográfico o de imprenta.<br>
          Su disponibilidad queda supeditada al stock existente.<br>
          Los presupuestos realizados con personalización o por cantidades especiales tendrán una validez de 15 días
          naturales, salvo fuerza mayor y/o causa extraordinaria, admitiéndose un ±5% de variación en las cantidades a
          entregar sobre el total del pedido inicial en prendas de vestir y accesorios y se abonará un 50% por
          adelantado del importe.<br>
          PLAÇA 18 SL se reserva el derecho de admisión y aceptación de los pedidos cursados, que deben ser autorizados
          por los gestores comerciales antes de su proceso y/o envío.<br>
          PLAÇA 18 SL, no se responsabiliza de los errores que el cliente pueda cometer al cursar los pedidos. Todos los
          pedidos son en firme, de forma que una vez cursados no es posible su modificación y/o cancelación.<br>
          No existe una cantidad de pedido mínima.<br>
          Nuestras ofertas de productos y precios son válidas mientras que sean visibles en el sitio, y lo serán hasta
          fin de existencias.<br>
          Para la resolución de cualquier cuestión o diferencia que pudiera surgir entre las partes con motivo de la
          relación de Cliente y Suministrador existente entre aquellas, ambas partes se someterán a los Juzgados y
          Tribunales de Palma de Mallorca, con renuncia expresa en su caso, a cualquier otro fuera que pudiera
          corresponderles.
        </p>
        <h2 class="text-h3 my-4">
          Formas de pago
        </h2>
        <ul>
          <li>Contado</li>
          <li>
            Transferencia (es de obligado cumplimiento pasar el justificante de la misma por correo electrónico a su
            comercial con la referencia del pedido, para que nuestro Departamento de Administración la compruebe antes
            de enviar la mercancía).
          </li>
          <li>Recibo domiciliado.</li>
          <li>Tarjeta de crédito</li>
        </ul>
        <h2 class="text-h3 my-4">
          Devoluciones
        </h2>
        <ul>
          <li>No se admiten devoluciones de prendas personalizadas.</li>
          <li>
            Antes de desembalar la mercancía, deberá hacer constar cualquier defecto de embalaje en el albarán de
            transporte a fin de habilitar el seguro de transporte y comunicarlo en 24h. No se aceptarán reclamaciones de
            bultos abiertos o extraviados si no se ha hecho constar en el albarán de entrega de la agencia de
            transportes dicha incidencia.
          </li>
          <li>
            Previamente con antelación a su envío todas las devoluciones deben ser autorizadas, por el departamento de
            atención al cliente, debiendo ser comunicada en un plazo máximo de 10 días naturales desde la fecha de
            recepción del producto, quien le confirmará la forma de recogida y le comunicará el número de expediente o
            devolución
            por correo electrónico, que deberá imprimir copia y depositar en el interior de la caja y fuera de ella para
            realizar el seguimiento posterior.
          </li>
          <li>
            Los abonos se realizarán una vez se haya comprobado que la mercancía recibida coincide con la autorizada en
            el expediente, contiene su embalaje original y está en perfecto estado. No se abonará mercancía que se
            reciba
            por encima de la autorizada en inicio por nuestro departamento de atención al cliente, la cual quedará a su
            disposición durante 5 días hábiles para que organice su retirada, una vez transcurrido este periodo ya no
            podrá
            recogerla.
          </li>
          <li>
            No se admiten devoluciones de prendas manipuladas por Ud., ni se admitirán reclamaciones sobre el importe de
            los trabajos de marcado sobre mercancía defectuosa, por ello debe comprobar la mercancía antes de realizar
            sobre la misma ningún trabajo de serigrafía, bordado, transfer, etc.
          </li>
          <li>
            Solo se admiten las reclamaciones basadas en motivos de servicio, errores propios por calidad y cantidad.
            No se aceptan devoluciones por motivos ajenos a los mencionados. En todo caso, no se admiten devoluciones de
            mercancía descatalogada, procedente del sitio outlet, deteriorada, manipulada y/o personalizada por Ud.,
            fuera de su embalaje original, ni fuera de los plazos establecidos.
          </li>
          <li>
            Para devoluciones de artículos no defectuosos que aceptemos, se aplicará un recargo del 10% sobre el
            precio de venta.
          </li>
          <li>
            El transporte de recogida va a cargo del cliente. En el caso de que el pedido hubiera ido a portes pagados
            se cobrará el porte de ida así como el de vuelta.
          </li>
        </ul>
        <h2 class="text-h3 my-4">
          Portes
        </h2>
        <ul>
          <li>Transporte GRATUITO para envíos superiores a 300€ en Península e Illes Balears (excepto Formentera).</li>
          <li>Envío por DHL</li>
        </ul>
      </div>
      <div v-else-if="$vuetify.lang.current === 'en'">
        <h1 class="text-h2 mb-4">
          Terms of sale
        </h1>
        <p>
          Placing an order implies acceptance of the General Conditions of Sale. <br>
        </p>
        <p>
          The products may undergo modifications with respect to the graphic reproduction in the catalog or web. <br>
          Prices may vary without prior notice. <br>
          Prices are expressed in euros and do not include value added tax (VAT) or others that
          could correspond at any time, except for typographical or printing errors.<br>
          Its availability is subject to the existing stock. <br>
          Budgets made with customization or for special quantities will be valid for 15 days
          natural, except force majeure and or extraordinary cause, admitting a ± 5% variation in the quantities to
          deliver on the total of the initial order in clothing and accessories and 50% will be paid for
          advance of the amount. <br>
          PLAÇA 18 SL reserves the right of admission and acceptance of the orders placed, which must be authorized
          by commercial managers before processing and or shipping. <br>
          PLAÇA 18 SL, is not responsible for errors that the customer may make when placing orders. All the
          Orders are firm, so that once they have been placed, their modification and or cancellation is not possible.
          <br>
          There is no minimum order quantity. <br>
          Our product and price offers are valid as long as they are visible on the site, and will be until
          end of stock. <br>
          For the resolution of any question or difference that may arise between the parties due to the
          Customer and Supplier relationship existing between them, both parties will submit to the Courts and
          Courts of Palma de Mallorca, expressly waiving where appropriate, to any other outside that could
          reciprocate.
        </p>
        <h2 class="text-h3 my-4">
          Payment
        </h2>
        <ul>
          <li>Cash</li>
          <li>
            Bankwire (it is mandatory to send the proof of the same by email to your commercial with the order
            reference, so that our Administration Department can check it before shipping the order).
          </li>
          <li>Direct debit receipt.</li>
          <li>Credit card</li>
        </ul>
        <h2 class="text-h3 my-4">
          Returns
        </h2>
        <ul>
          <li>Returns of customized garments are not allowed.</li>
          <li>
            Before unpacking the merchandise, you must state any packaging defect on the delivery note.
            transport in order to enable transport insurance and communicate it within 24 hours. No claims will be
            accepted open or lost packages if it has not been stated in the delivery note of the agency of
            transport said incident.
          </li>
          <li>
            Prior to shipment, all returns must be authorized by the department of
            customer service, which must be communicated within a maximum period of 10 calendar days from the date of
            receipt of the product, who will confirm the form of collection and communicate the file number or
            return by email, which you must print a copy and deposit inside the box and outside of it to
            follow up later.
          </li>
          <li>
            The payments will be made once it has been verified that the merchandise received matches that authorized in
            the file contains its original packaging and is in perfect condition. Merchandise that is
            receive above that authorized initially by our customer service department, which will be up to your
            available for 5 business days to organize its withdrawal, once this period has elapsed no longer
            may pick her up.
          </li>
          <li>
            Returns of garments handled by you are not allowed, nor will claims on the amount of
            marking work on defective merchandise, therefore you must check the merchandise before carrying out
            no serigraphy, embroidery, transfer, etc. work on it.
          </li>
          <li>
            Only claims based on service reasons, own errors due to quality and quantity are allowed.
            Returns are not accepted for reasons other than those mentioned. In any case, returns of
            discontinued merchandise, from the outlet site, damaged, manipulated and or personalized by you,
            out of its original packaging, nor outside the established deadlines.
          </li>
          <li>
            For returns of non-defective items that we accept, a 10% surcharge will be applied on the
            sale price.
          </li>
          <li>
            The collection transport is at the customer's expense. In the event that the order had been prepaid
            The outbound as well as the return postage will be charged.
          </li>
        </ul>
        <h2 class="text-h3 my-4">
          Shipping costs
        </h2>
        <ul>
          <li>
            FREE shipping for orders over € 300 in the Peninsula and the Balearic Islands (except Formentera).
          </li>
          <li>Shipping by DHL</li>
        </ul>
      </div>
      <div v-else-if="$vuetify.lang.current === 'ca'">
        <h1 class="text-h2 mb-4">
          Condicions de venda
        </h1>
        <p>
          La realització d'una comanda, suposa l'acceptació de les Condicions Generals de Venda.<br>
        </p>
        <p>
          Els productes poden sofrir modificacions respecte a la reproducció gràfica en catàleg o web.<br>
          Els preus poden variar sense previ avís.<br>
          Els preus s'expressen en euros i no inclouen l'impost sobre el valor afegit (I.V. A.) o altres que
          poguessin correspondre a cada moment, excepte error tipogràfic o d'impremta.<br>
          La seva disponibilitat queda supeditada a l'estoc existent.<br>
          Els pressupostos realitzats amb personalització o per quantitats especials tindran una validesa de 15 dies
          naturals, excepte força major i/o causa extraordinària, admetent-se un ±5% de variació en les quantitats a lliurar
          sobre el total de la comanda inicial en peces de vestir i accessoris i s'abonarà un 50% per endavant
          de l'import.<br>
          PLAÇA 18 SL es reserva el dret d'admissió i acceptació de les comandes cursades, que han de ser autoritzats
          pels gestors comercials abans del seu procés i/o enviament.<br>
          PLAÇA 18 SL, no es responsabilitza dels errors que el client pugui cometre en cursar les comandes. Totes les
          comandes són en ferma, de manera que una vegada cursats no és possible la seva modificació i/o cancel·lació.<br>
          No existeix una quantitat de comanda mínima.<br>
          Les nostres ofertes de productes i preus són vàlides mentre que siguin visibles en el lloc, i ho seran fins a fi
          d'existències.<br>
          Per a la resolució de qualsevol qüestió o diferència que pogués sorgir entre les parts amb motiu de la
          relació de Client i Subministrador existent entre aquelles, totes dues parts se sotmetran als Jutjats i
          Tribunals de Palma, amb renúncia expressa en el seu cas, a qualsevol altre fora que pogués
          correspondre'ls.
        </p>
        <h2 class="text-h3 my-4">
          Formes de pagament
        </h2>
        <ul>
          <li>Comptat</li>
          <li>
            Transferència (és d'obligat compliment passar el justificant de la mateixa per correu electrònic al seu
            comercial amb la referència de la comanda, perquè el nostre Departament d'Administració la comprovi abans
            d'enviar la mercaderia).
          </li>
          <li>Rebut domiciliat.</li>
          <li>Targeta de crèdit</li>
        </ul>
        <h2 class="text-h3 my-4">
          Devolucions
        </h2>
        <ul>
          <li>No s'admeten devolucions de peces personalitzades.</li>
          <li>
            Abans de desembalar la mercaderia, haurà de fer constar qualsevol defecte d'embalatge en l'albarà de transport
            a fi d'habilitar l'assegurança de transport i comunicar-lo en 24h. No s'acceptaran reclamacions d'embalums
            oberts o extraviats si no s'ha fet constar en l'albarà de lliurament de l'agència de transports
            aquesta incidència.
          </li>
          <li>
            Prèviament amb antelació al seu enviament totes les devolucions han de ser autoritzades, pel departament d'atenció
            al client, havent de ser comunicada en un termini màxim de 10 dies naturals des de la data de recepció
            del producte, qui li confirmarà la forma de recollida i li comunicarà el número d'expedient o
            devolució
            per correu electrònic, que haurà d'imprimir còpia i dipositar a l'interior de la caixa i fora d'ella per a realitzar
            el seguiment posterior.
          </li>
          <li>
            Els abonaments es realitzaran una vegada s'hagi comprovat que la mercaderia rebuda coincideix amb l'autoritzada en
            l'expedient, conté el seu embalatge original i està en perfecte estat. No s'abonarà mercaderia que es rebi

            per sobre de l'autoritzada en inici pel nostre departament d'atenció al client, la qual quedarà a la seva
            disposició durant 5 dies hàbils perquè organitzi la seva retirada, una vegada transcorregut aquest període ja no
            podrà
            recollir-la.
          </li>
          <li>
            No s'admeten devolucions de peces manipulades per Vostè, ni s'admetran reclamacions sobre l'import dels
            treballs de marcat sobre mercaderia defectuosa, per això ha de comprovar la mercaderia abans de realitzar
            sobre la mateixa cap treball de serigrafia, brodat, transfer, etc.
          </li>
          <li>
            Només s'admeten les reclamacions basades en motius de servei, errors propis per qualitat i quantitat.
            No s'accepten devolucions per motius aliens als esmentats. En tot cas, no s'admeten devolucions de mercaderia
            descatalogada, procedent del lloc outlet, deteriorada, manipulada i/o personalitzada per Vostè,
            fora del seu embalatge original, ni fora dels terminis establerts.
          </li>
          <li>
            Per a devolucions d'articles no defectuosos que acceptem, s'aplicarà un recàrrec del 10% sobre el
            preu de venda.
          </li>
          <li>
            El transport de recollida va a càrrec del client. En el cas que la comanda hagués anat a ports pagats
            es cobrarà el port d'anada així com el de tornada.
          </li>
        </ul>
        <h2 class="text-h3 my-4">
          Ports
        </h2>
        <ul>
          <li>Transport GRATUÏT per a enviaments superiors a 300€ en Península i Illes Balears (excepte Formentera).</li>
          <li>Enviament per DHL</li>
        </ul>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TermsAndConditionsPage',
  metaInfo () {
    return {
      title: this.$vuetify.lang.t('$vuetify.terms_and_conditions')
    }
  }
}
</script>

<style scoped>

</style>
