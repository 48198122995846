export const websiteConfig = {
  data: () => ({
    pl18: {
      appName: 'TPV Plaça 18',
      appWebsite: 'Plaça 18',
      appPhone: '971 660 422'
    },
    textilmallorca: {
      appName: 'TPV textilmallorca',
      appWebsite: 'textilmallorca',
      appPhone: '971 660 315'
    },
    devuit: {
      appName: 'TPV devuitNature',
      appWebsite: 'devuitNature',
      appPhone: '971 660 422'
    }
  })
}
