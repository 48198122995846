<template>
  <span
    v-if="payedAt === null"
    class="text-h4 text--lighten-3"
    :class="isDueDate(this.dueDate) ? 'red--text' : 'green--text'"
  >
    <v-icon
      v-if="isDueDate(this.dueDate)"
      color="red lighten-3"
      large
    >
      mdi-close
    </v-icon>
    <v-icon
      v-else
      color="green lighten-3"
      large
    >
      mdi-check
    </v-icon> {{ parsedDate | moment('DD-MM-YYYY') }}
  </span>
  <span
    class="text-h4 text--lighten-3"
    v-else
  >
    {{ parsedDate | moment('DD-MM-YYYY') }}
  </span>
</template>

<script>
import { dueDateMixin } from '../mixins/dueDateMixin'

export default {
  name: 'DueDateText',
  props: {
    dueDate: {
      type: String,
      default: ''
    },
    payedAt: {
      type: String,
      default: null
    }
  },
  mixins: [dueDateMixin],
  computed: {
    parsedDate: function () {
      return new Date(this.dueDate.replace(' ', 'T'))
    }
  }
}
</script>

<style scoped>

</style>
