<template>
  <v-card
    class="rounded-xl pa-5"
  >
    <v-card-text>
      <h1 class="text-lg-h2 text-md-h3">
        {{ $vuetify.lang.t('$vuetify.welcome') }}
      </h1>
      <p class="mt-5">
        {{ $vuetify.lang.t('$vuetify.platform') }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'HomePage',
  metaInfo () {
    return {
      title: this.$vuetify.lang.t('$vuetify.welcome')
    }
  }
}
</script>

<style scoped>

</style>
